<template>
  <v-dialog :value="isOpen" :max-width="600" @input="close" persistent>
    <v-card>
      <div v-if="isSyncing" class="text-xs-center">
        <v-card-title>
          <div class="title">Syncing memberships data...</div>
        </v-card-title>
        <v-progress-circular indeterminate color="primary" />
      </div>
      <div v-else>
        <v-card-title>
          <div class="title">
            Membership sync complete successfully
            <v-icon color="green">done</v-icon>
          </div>
        </v-card-title>
      </div>
      <v-card-text v-if="isSyncing">
        <p>
          This process takes a few minutes to complete. Please be patient, the
          page will refresh automatically once the process is done.
        </p>
      </v-card-text>
      <v-card-actions v-if="!isSyncing">
        <v-spacer />
        <v-btn color="primary" flat @click.native="close">close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SyncDialog',
  components: {},
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    isSyncing: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {},
  methods: {
    close() {
      this.$emit('close-dialog');
    }
  }
};
</script>

<style scoped></style>
