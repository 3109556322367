import { render, staticRenderFns } from "./ConfirmSyncDialog.vue?vue&type=template&id=5fb7e071&scoped=true"
import script from "./ConfirmSyncDialog.vue?vue&type=script&lang=js"
export * from "./ConfirmSyncDialog.vue?vue&type=script&lang=js"
import style0 from "./ConfirmSyncDialog.vue?vue&type=style&index=0&id=5fb7e071&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fb7e071",
  null
  
)

export default component.exports