<template>
  <div class="text-xs-center">
    <v-dialog :value="isOpen" :width="600" @input="handleClose">
      <v-card>
        <v-card-title>
          <div class="title">Run a Manual Sync?</div>
        </v-card-title>

        <v-card-text>
          <p>
            Synchronize membership data from your
            {{ integrationName }} database?
          </p>
          <p>Note: You may only run a manual sync <b>once per hour</b>.</p>

          <p v-if="ttl >= 0">
            <i>
              <b>
                A manual sync was run recently, please wait until
                {{ endTime }} to run another manual sync.
              </b>
            </i>
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn flat text @click="handleCancel">
            close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="isAdmin && ttl >= 0"
            color="primary"
            flat
            :loading="loading"
            @click="handleForceSync"
          >
            force sync
          </v-btn>
          <v-btn
            color="primary"
            :disabled="ttl >= 0"
            :loading="loading"
            @click="handleConfirm"
          >
            confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ConfirmSyncDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    ttl: {
      type: Number,
      default: -1
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    integrationName: {
      type: String,
      default: ''
    },
    meetingTimezone: {
      type: String,
      default: ''
    }
  },
  computed: {
    endTime() {
      const duration = moment.duration(this.ttl, 'seconds');
      const endTime = moment().add(duration);
      const timezone = this.meetingTimezone;
      return endTime.tz(timezone).format('h:mm a zz');
    }
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm', true);
    },
    handleCancel() {
      this.$emit('confirm', false);
    },
    handleClose() {
      this.$emit('close');
    },
    handleForceSync() {
      this.$emit('force', true);
    }
  }
};
</script>

<style scoped>
.round-card {
  border-radius: 7px;
}
</style>
