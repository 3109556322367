// Polyfill for promises
import es6Promise from 'es6-promise';
es6Promise.polyfill();

import Pusher from 'pusher-js';
import http from '../api/requestType';
import Vuex from 'vuex';
import Vue from 'vue';
import _ from 'lodash';

// Vuex Nested Modules
import admin from './modules/admin';
import meetings from './modules/meetings';
import v2 from './modules/v2';

// Vuex Global Modules
import accounts from './modules/accounts';
import agents from './modules/agents';
import chat from './modules/chat';
import features from './modules/features';
import campaigns from './modules/campaigns';
import checklists from './modules/checklists';
import checklistTemplates from './modules/checklist-templates';
import checklistTemplateGroups from './modules/checklist-template-groups';
import invoices from './modules/invoices';
import files from './modules/files';
import noticeTemplates from './modules/notice-templates';
import conversationTemplateGroups from './modules/conversation-template-groups';
import notices from './modules/notices';
import serviceAgreements from './modules/service-agreements';
import users from './modules/users';
import voters from './modules/voters';
import products from './modules/products';
import vTour from './modules/vtour';
import contacts from './modules/contacts';

Vue.use(Vuex);

import router from '@/routes';
import * as authApi from '@/api/auth';
import _debug from '@/lib/debug';

Pusher.logToConsole = process.env.NODE_ENV === 'development';

const PUSHER_KEY = process.env.VUE_APP_PUSHER_KEY;
const APP_AUTH_BASE_URL = process.env.VUE_APP_AUTH_BASE_URL;

const state = {
  login: {
    profile: {
      admin: false,
      type: null,
      email: '',
      scopes: {
        campaigns: []
      }
    }
  },
  googleClient: null
};

const getters = {
  login: state => state.login,
  route: state => state.route,
  isAdmin: state => {
    if (!_.has(state.login, 'profile')) {
      return false;
    }
    return state.login.profile.admin || state.login.profile.type === 'admin';
  },
  scopes: state => state.login.profile.scopes,
  getScopeByAction: state => (shortCode, action) => {
    const actions = _.get(
      state.login.profile.scopes.campaigns,
      `${shortCode}.actions`,
      []
    );
    return actions.includes(action);
  },
  googleClient: state => state.googleClient
};

const actions = {
  /**
   * Login and retrieve the token from API
   *
   * @param  {Object} obj           - the login profile
   * @param  {String} obj.username  - the username
   * @param  {String} obj.password  - the password
   * @param  {String} obj.path      - the login path
   */
  async doLogin({ commit, dispatch }, { username, password, path }) {
    try {
      // Attempt login.
      // Note: unlike other API calls, this one doesn't throw an error
      // if response is not 2XX.
      const res = await authApi.postAuthToken({ username, password });

      if (!res) {
        throw new Error('RESPONSE_ERROR');
      }

      const status = res.status;
      const data = res.data;
      const wwwAuth = res.headers['www-authenticate'];

      // Account hasn't been used for a X amount of days
      // Needs password reset in order to login
      if (data && data.reset) {
        return router.push({ name: 'inactiveProfile', params: { username } });
      }

      // Case 1: it's a successful login and no MFA is needed
      if (status >= 200 && status < 300) {
        await dispatch('getUserProfile');

        if (path) {
          // Go to redirect URL after successful login.
          // If redirect URL is invalid they get unauthenticated view
          return (window.location.href = path);
        } else {
          // Default login path to meetings overview
          return router.push({ name: 'meetings' });
        }
      }

      // Case 2: Check if a multifactor token is required
      else if (status === 401 && wwwAuth === 'mfaToken') {
        // Jump to the MFA page
        return router.push({
          name: 'verify',
          params: { username, password, path }
        });
      }

      // Case 3: Authentication failed
      else {
        const err = new Error();
        err.response = res;
        err.alert = status === 429 ? 'attempts-exceeded' : 'login-failed';
        throw err;
      }
    } catch (err) {
      console.error('Error logging in', err.response);
      throw err;
    }
  },

  /**
   * Login and retrieve the token from API using multifactor auth route.
   *
   * @param  {Object} obj                 - the login profile
   * @param  {String} obj.username        - the username
   * @param  {String} obj.password        - the password
   * @param  {String} obj.path            - the login path
   * @param  {String} obj.token           - the mfa token
   * @param  {String} obj.trustThisDevice - check if the "trust" checkbox is checked
   */
  async doMFALogin(
    { commit, dispatch },
    { username, password, path, token, trustThisDevice }
  ) {
    try {
      // Attempt login with MFA token.
      const res = await authApi.postAuthToken({
        username,
        password,
        token,
        trustThisDevice
      });

      if (!res) {
        throw new Error('RESPONSE_ERROR');
      }

      const status = res.status;

      // Case 1: MFA verification is sucessful.
      if (status >= 200 && status < 300) {
        await dispatch('getUserProfile');

        if (path) {
          // Go to redirect URL after successful login.
          // If redirect URL is invalid they get unauthenticated view
          return (window.location.href = path);
        } else {
          // Default login path to meetings overview
          return router.push({ name: 'meetings' });
        }
      }

      // Case 2: MFA Rate Limit Reached. Punt it back to login with message
      else if (status === 429) {
        return router.push({
          name: 'login',
          query: { alert: 'attempts-exceeded' }
        });
      }

      // Case 3: Authentication failed
      else {
        const err = new Error();
        err.response = res;
        err.alert = 'mfa-failed';
        throw err;
      }
    } catch (err) {
      console.error('Error MFA Login', err.response);
      throw err;
    }
  },

  /**
   * Trigger sending the MFA Token to the User
   *
   * @param  {Object} obj
   * @param  {String} obj.username  - the username
   * @param  {String} obj.password  - the password
   */
  async sendTokenChallenge({ commit, dispatch }, { username, password }) {
    try {
      const res = await authApi.postAuthTokenChallenge({ username, password });

      if (!res) {
        throw new Error('RESPONSE_ERROR');
      }

      const status = res.status;

      // Case 1: MFA Rate Limit Reached. Punt it back to login with message
      if (status === 429) {
        return router.push({
          name: 'login',
          query: { alert: 'attempts-exceeded' }
        });
      }
      // Case 2: Some unknown error, just throw
      else if (status < 200 || status >= 300) {
        const err = new Error();
        err.response = res;
        throw err;
      }
      // Otherwise everything is ok.
      else {
        return;
      }
    } catch (err) {
      console.error('Error sending token challenge', err.response);
      throw err;
    }
  },

  /**
   * Initialize the google token client
   * Handle callback to get the JWT token from API
   */
  initGoogleLogin({ commit, dispatch }) {
    const client = window.google.accounts.oauth2.initTokenClient({
      client_id: process.env.VUE_APP_GOOGLE_APP_ID,
      scope: 'openid  profile  email',
      callback: async response => {
        try {
          await authApi.postOAuthToken('google', response.access_token);
          await dispatch('getUserProfile');

          // Check for redirect path
          const path = router.currentRoute.query?.redirect;

          if (path) {
            // Redirect login path for session expires, if redirect URL is invalid they get unauthenticated view
            window.location.href = path;
          } else {
            // Default login path to meetings overview
            router.push({ name: 'meetings' });
          }
        } catch (err) {
          console.error(err);
          dispatch('clearCredentials');
          if (err.response) {
            // If status is 503, navigate to maintenance page
            if (err.response.status === 503) {
              if (router.currentRoute.name !== 'maintenance') {
                router.push({ name: 'maintenance' });
              }
            } else {
              // If other status, open error dialog message
              Vue.prototype.$events.$emit('showErrorDialog', err.response);
            }
          } else if (err.request) {
            if (router.currentRoute.name !== 'maintenance') {
              router.push({ name: 'maintenance' });
            }
          }
          throw err;
        }
      }
    });

    commit('SET_GOOGLE_CLIENT', client);
  },

  async doRefreshToken({ dispatch }) {
    _debug('Attempting token refresh');
    await authApi.postOAuthRefreshToken();
    await dispatch('getUserProfile');
  },

  /**
   * Get the user's profile. Requires user to already be logged in.  Essentially
   * it's a login check.
   */
  async getUserProfile({ commit }) {
    try {
      const res = await authApi.getUserInfo();
      sessionStorage.setItem('profile', JSON.stringify(res.data));
      commit('SET_LOGIN_PROFILE', res.data);
      return res.data;
    } catch (err) {
      console.log('Error getting user profile', err);
      if (err.response) {
        // If status is 503, navigate to maintenance page
        if (err.response.status === 503) {
          if (router.currentRoute.name !== 'maintenance') {
            router.push({ name: 'maintenance' });
          }
          //Throw error to prevent looping redirects
          throw err;
        }
      } else if (err.request) {
        if (router.currentRoute.name !== 'maintenance') {
          router.push({ name: 'maintenance' });
        }
        //Throw error to prevent looping redirects
        throw err;
      }
      return null;
    }
  },

  /**
   * Dispatch a request to the API for a password reset email
   * @param {String}  email - the email of the account requesting a reset
   * @return {Promise}      - the response
   */
  requestPasswordReset({ dispatch }, email) {
    return authApi.postForgotPassword(email);
  },

  /**
   * Check if a resetToken or inviteToken is valid
   * @param {String}  token - the reset token
   * @param {String}  type  - the token type (eg. 'invite' or 'reset')
   * @return {Promise}           - the response
   */
  checkPasswordResetToken({ dispatch }, { token, type }) {
    return authApi.getPasswordReset(token, type);
  },

  /**
   * Set the user's new password
   *
   * @param {Object}  obj              - the object parameters
   * @param {String}  obj.token        - the token value
   * @param {String}  obj.type         - the token type ('invite', 'reset')
   * @param {String}  obj.newPassword  - the new password
   * @return {Promise}                 - the response
   */
  doResetPassword({ dispatch }, { token, type, newPassword }) {
    return authApi.postPasswordReset(token, type, newPassword);
  },

  /**
   * Changes the currently logged in users password
   *
   * @param {Object} obj             - the object parameters
   * @param {string} obj.username    - the email of the user
   * @param {string} obj.password    - the current password
   * @param {string} obj.newPassword - the new password
   * @return {Promise}
   */
  async doChangePassword({ dispatch }, { password, newPassword }) {
    try {
      const res = await authApi.putPasswordChange({
        password,
        newPassword
      });
      const status = res.status;
      if (status === 200) {
        await authApi.postLogout().catch(err => console.log(err));
        dispatch('clearCredentials');
        return res;
      }
      const err = new Error();
      // 400 case (missing/not matching schema)
      if (status === 400) {
        err.message = res.data.message;
        err.type = res.data.type;
      }
      // 401 case (unauth, incorrect password)
      else if (status === 401) {
        err.type = 'currentPassword';
        err.message = 'Incorrect password';
      }
      throw err;
    } catch (err) {
      console.error('Error changing password', err);
      throw err;
    }
  },
  /**
   * Logout a user
   */
  doLogout({ dispatch }) {
    // Logout via the API
    authApi.postLogout().catch(err => console.log(err));

    dispatch('clearCredentials');
    dispatch('chat/chatDestroy', null, { root: true });
    router.push({ name: 'login', query: { alert: 'logout' } });
  },

  /**
   * Clear credentials for user
   */
  clearCredentials({ dispatch, commit }) {
    dispatch('meetings/clearMeetingState', null, { root: true });
    dispatch('meetings/clearState', null, { root: true });
    commit('CLEAR_PROFILE');
    sessionStorage.removeItem('profile');
  },
  setLoginProfile({ commit }, profile) {
    commit('SET_LOGIN_PROFILE', profile);
  },

  /**
   * Subscribe to this meeting's Pusher channel
   * @param  {String} {shortCode}  - meeting shortcode
   */
  initPusher({ state, commit, dispatch }, { shortCode }) {
    try {
      const pusher = new Pusher(PUSHER_KEY, {
        cluster: 'us2',
        authEndpoint: `${APP_AUTH_BASE_URL}/pusher`,
        encrypted: true,
        activityTimeout: 120000,
        authorizer: function(channel, options) {
          return {
            authorize: function(socketId, cb) {
              return http
                ._post(options.authEndpoint, {
                  socket_id: socketId,
                  channel_name: channel.name,
                  plaftom: 'dashboard'
                })
                .then(res => cb(null, res.data))
                .catch(err => {
                  console.error('Error authorizing with pusher', err);
                  throw err;
                });
            }
          };
        }
      });

      // Save to store, so we can force disconnect
      commit('SET_PUSHER', pusher);

      // Subscribe to main pusher channel for Dashboard events for this meeting
      const channel = state.pusher.subscribe(`dash-${shortCode}`);

      // Define all events and subscriptions to listen for here.
      // For event to pop up a chat beacon message
      channel.bind('show-beacon-message', data => {
        dispatch('chat/showChatMessage', data, { root: true });
      });

      // Subscribe to RTV question updates
      // -- Handle RTV events
      channel.bind('update-question', () => {
        dispatch(
          'meetings/rtvQuestions/getMeetingRTVQuestions',
          { shortCode },
          { root: true }
        );
      });

      // -- Handle notes events
      channel.bind('post-notes', () => {
        dispatch('meetings/notes/getMeetingNotesList', {
          shortCode,
          noteType: ''
        });
      });

      // -- Handle live votes and questions events
      // Listen for votes cast in RTV
      channel.bind('vote-cast', () => {
        dispatch('meetings/liveVotes/getMeetingRTVLiveVotes', {
          shortCode
        });
        // This has been temporarily commented out, as it only affects v2 overview
        // dispatch('v2/questions/fetchQuestionsCombined', { shortCode });
      });

      // -- Handle approval request events
      channel.bind('review-approval', () => {
        dispatch('meetings/approvals/getMeetingApprovalsList', {
          shortCode
        });
      });

      channel.bind('add-approval', () => {
        dispatch('meetings/approvals/getMeetingApprovalsList', {
          shortCode
        });
      });

      channel.bind('update-approval', () => {
        dispatch('meetings/approvals/getMeetingApprovalsList', {
          shortCode
        });
      });

      channel.bind('remove-approval-user', () => {
        dispatch('meetings/approvals/getMeetingApprovalsList', {
          shortCode
        });
      });

      channel.bind('add-approval-user', () => {
        dispatch('meetings/approvals/getMeetingApprovalsList', {
          shortCode
        });
      });

      // -- Handle meeting settings events

      channel.bind('meeting-settings-update', data => {
        // user key that made the update
        const { key: userKey } = data;
        const {
          login: { profile }
        } = state;

        const currentView = document.hasFocus();

        if (profile.key !== userKey || !currentView) {
          commit('meetings/SET_RELOAD_DIALOG', { isOpen: true });
        }
      });

      // -- Handle sync memberships data status
      channel.bind('sync-memberships', () => {
        dispatch('meetings/units/getMeetingUnits', {
          shortCode
        });

        dispatch('meetings/units/setIsSyncing', { isSyncing: false });
      });

      // Subscribe to main pusher channel for RTV events for this meeting
      const channelRTV = state.pusher.subscribe(`rtv-${shortCode}`);

      channelRTV.bind('toggle-revocations', data => {
        dispatch('meetings/getMeeting', {
          shortCode
        });
      });

      // Subscribe to main presence channel
      const presenceChannel = pusher.subscribe(`presence-dash-${shortCode}`);

      presenceChannel.bind('get-stats', data => {
        console.log('retrieving stats from subscription ', data);
        commit('meetings/SET_MEETING_STAT', { shortCode, data });
        commit('meetings/SET_LOADING_STATUS', {
          type: 'stats',
          status: false
        });
      });

      // handle sync memberships data status
    } catch (err) {
      console.error('Error: initPusher', err);
      throw err;
    }
  },
  /**
   * Disconnect from this meeting's RTV Pusher channel
   */
  destroyPusher({ state, commit }) {
    if (!_.isEmpty(state.pusher)) {
      commit('CLEAR_PUSHER');
    }
  }
};

const mutations = {
  SET_LOGIN_PROFILE(state, profile) {
    state.login.profile = profile;
  },
  CLEAR_PROFILE(state) {
    state.login.profile = {
      admin: false,
      type: null
    };
  },
  SET_PUSHER(state, pusher) {
    state.pusher = pusher;
  },
  CLEAR_PUSHER(state) {
    state.pusher.disconnect();
    state.pusher = null;
  },
  SET_GOOGLE_CLIENT(state, client) {
    state.googleClient = client;
  }
};

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    admin,
    meetings,
    accounts,
    agents,
    campaigns,
    chat,
    checklists,
    checklistTemplates,
    checklistTemplateGroups,
    features,
    files,
    invoices,
    notices,
    noticeTemplates,
    products,
    serviceAgreements,
    conversationTemplateGroups,
    users,
    voters,
    v2,
    vTour,
    contacts
  }
});

export default store;
