<template>
  <v-container fluid>
    <v-layout row class="ma-0 pa-0">
      <v-flex xs4>
        <!-- Filter by Question -->
        <v-select
          v-model="questionFilter"
          :items="listQuestions"
          label="Filter By Question"
          item-text="question"
          item-value="question"
          clearable
          dense
          solo
          small
          outlined
          @change="setQuestionSelected"
          @click:clear="resetFilterByQuestion"
        ></v-select>
      </v-flex>
      <v-flex xs4>
        <!-- Filter by Vote base on question selected -->
        <v-select
          class="ml-2"
          v-if="showFilterByVote && canSeeFullResult"
          :items="listVotes"
          filled
          label="Filter By Vote"
          item-text="item"
          item-value="item"
          clearable
          dense
          solo
          @change="setVoteSelected"
          @click:clear="resetFilterByVote"
        ></v-select>
      </v-flex>
      <v-flex xs4>
        <div class="refresh">
          <span class="caption">
            <span v-if="isLoading">Loading...</span>
            <span v-else>Updated {{ refreshStatus }}</span>
          </span>
          <v-btn
            flat
            icon
            :loading="isLoading"
            color="secondary"
            @click.native="getLatest"
          >
            <v-icon>cached</v-icon>
          </v-btn>
        </div>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12>
        <v-toolbar light color="grey lighten-4 elevation-1">
          <!-- Custom Text Search -->
          <v-text-field
            prepend-icon="search"
            label="Search"
            single-line
            hide-details
            clearable
            v-model="search"
          />

          <v-spacer />

          <template class="buttons-slot">
            <!-- Vote Reset Button -->
            <v-btn
              :disabled="!isSelectedRows"
              class="accent"
              @click.native="dialog.reset = true"
            >
              Reset {{ isProxyAndBallot ? 'Ballots' : 'Answers' }} ({{
                numOfSelectedRows
              }})
            </v-btn>

            <!-- Export All Votes Report -->
            <v-btn class="primary" @click.native="exportCsvClicked">
              <v-icon class="mr-1">launch</v-icon> Export CSV
            </v-btn>
            <v-btn class="primary" @click.native="exportPdfClicked">
              <v-icon class="mr-1">launch</v-icon> Export PDF
            </v-btn>
          </template>
        </v-toolbar>

        <!-- Voting Answers Table -->
        <v-data-table
          :headers="headers"
          :items="filterData"
          :search="search"
          v-model="selected"
          @input="setSelected"
          class="elevation-1"
          select-all
          :rows-per-page-items="config.rowsPerPageItems"
        >
          <template slot="items" slot-scope="props">
            <tr :key="props.index">
              <td>
                <v-checkbox primary hide-details v-model="props.selected" />
              </td>
              <td class="text-xs-left" v-if="!isVbmMeeting">
                {{ props.item.unit }}
              </td>
              <td class="text-xs-left">
                {{ props.item.name }}
              </td>
              <td class="text-xs-left">
                {{ props.item.email }}
              </td>
              <td class="text-xs-left">
                {{ props.item.question }}
              </td>
              <td v-if="canSeeFullResult" class="text-xs-left">
                {{ props.item.vote }}
              </td>
              <td v-if="canSeeFullResult" class="text-xs-left">
                {{ props.item.shares }}
              </td>
              <td v-if="canSeeFullResult" class="text-xs-left">
                {{ formatCustomData(props.item) }}
              </td>
              <td class="text-xs-left">
                {{ props.item.submitted }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <!-- Reset Voter Answers Dialog -->
    <v-dialog v-model="dialog.reset" max-width="400px">
      <v-card>
        <v-card-title> <div class="title">Are you sure?</div> </v-card-title>
        <v-card-text
          >Click to <strong>RESET</strong> these voter answer(s).</v-card-text
        >
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click.native="dialog.reset = false">close</v-btn>
          <v-btn class="primary" @click.native="resetAnswers">Reset</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Export PDF Dialog -->
    <export-files-dialog
      :is-open="showExportDialog"
      :processing="isExportProcessing"
      :error="exportDialogError"
      :email-to="login.profile.email"
      @close-dialog="closeExportDialog"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  getMeetingRTVLiveAnswersData,
  resetMeetingVoterAnswers
} from '@/api/meetings-rtv-live-votes';
import { convertArrayOfObjectsToCSV, downloadCSV } from '@/helpers';
import checkScope from '@/lib/check-user-scopes';
import _ from 'lodash';
import moment from 'moment';
import ExportFilesDialog from '@/components/dialogs/ExportFilesDialog';

export default {
  name: 'MeetingRTVLiveAnswersView',
  components: { ExportFilesDialog },
  props: {},
  data() {
    return {
      state: {},
      tableData: [],
      search: '',
      questionFilter: '',
      config: {
        rowsPerPageItems: [25, 50, 100, { text: 'All', value: -1 }]
      },
      shortCode: this.$route.params.shortcode,
      parent: this.$route.params.parent,
      selected: [],
      voteSelected: null,
      questionSelected: null,
      showFilterByVote: false,
      dialog: {
        reset: false
      },
      listVotes: [],
      isLoading: false,
      refreshStatus: null,
      lastRefresh: new Date()
    };
  },
  watch: {
    selected: function(selected) {
      this.setSelected(selected);
    }
  },
  computed: {
    ...mapGetters('meetings/reports', [
      'showExportDialog',
      'isExportProcessing',
      'exportDialogError'
    ]),
    ...mapGetters(['isAdmin', 'login', 'scopes']),
    isVbmMeeting() {
      // Used to hide unit column for vbm
      return this.$attrs['current-meeting']?.options.type === 'vbm';
    },
    isProxyAndBallot() {
      return (
        this.$attrs['current-meeting']?.options.votingOption === 'Proxy-Ballot'
      );
    },
    isSelectedRows() {
      return this.selected.length > 0;
    },
    numOfSelectedRows() {
      return this.selected.length;
    },
    listQuestions() {
      return _.uniqBy(
        _.map(
          this.tableData,
          _.partialRight(_.pick, ['questionId', 'question'])
        ),
        'questionId'
      );
    },
    filterData() {
      return this.filterByVote(this.filterByQuestion(this.tableData));
    },
    // those can see full result: admin
    canSeeFullResult() {
      return (
        this.isAdmin ||
        checkScope(this.scopes, this.shortCode, 'meeting.live-votes.full.read')
      );
    },
    headers() {
      let headers = [];

      // Conditional table column display
      if (this.canSeeFullResult) {
        headers = [
          { value: 'unit', text: 'Unit', align: 'left' },
          { value: 'name', text: 'Voter', align: 'left' },
          { value: 'email', text: 'Email', align: 'left' },
          { value: 'question', text: 'Question', align: 'left' },
          { value: 'vote', text: 'Vote', align: 'left' },
          { value: 'shares', text: 'Shares', align: 'left' },
          { text: 'Custom Data', sortable: false, align: 'left' },
          { value: 'submitted', text: 'Date', align: 'left' }
        ];
      } else {
        // Note custom width
        headers = [
          { value: 'unit', text: 'Unit', align: 'left', width: '5%' },
          { value: 'name', text: 'Voter', align: 'left', width: '15%' },
          { value: 'email', text: 'Email', align: 'left', width: '15%' },
          { value: 'question', text: 'Question', align: 'left', width: '40%' },
          {
            value: 'submitted',
            text: 'Vote Received',
            align: 'left',
            width: '25%'
          }
        ];
      }

      // If VBM, exclude unit column
      if (this.isVbmMeeting) {
        headers = headers.filter(h => h.value !== 'unit');
      }

      return headers;
    }
  },
  created() {
    this.getLatest();
  },
  mounted() {
    let self = this;
    // Update the data age clock every minute
    setInterval(function() {
      self.updateRefreshStatus();
    }, 60000);

    const question = this.$route.query.filter;
    if (question) {
      this.questionFilter = question;
      this.setQuestionSelected(question);
    }
  },
  methods: {
    ...mapActions('meetings/reports', ['exportReport', 'closeExportDialog']),
    async getLatest() {
      try {
        this.isLoading = true;

        const res = await getMeetingRTVLiveAnswersData(this.shortCode);

        if (res.status !== 200) {
          throw new Error(res.text);
        }
        this.tableData = res.data.map(row => {
          return {
            ...row,
            vote: row.answers.join(', '),
            submitted: moment(new Date(row.submitted)).fromNow()
          };
        });

        // Set the latest refresh and update the refresh status
        this.lastRefresh = new Date();
        this.updateRefreshStatus();
      } catch (err) {
        throw err;
      } finally {
        this.isLoading = false;
      }
    },
    setSelected(selected) {
      this.selected = selected;
    },
    exportCsvClicked() {
      // Do not mutate original table data
      let tableDataClone = _.cloneDeep(this.tableData);

      // Hide specific columns if user cannot see full results
      if (!this.canSeeFullResult) {
        tableDataClone = tableDataClone.map(row => {
          return {
            name: row.name,
            email: row.email,
            question: row.question,
            submitted: row.submitted
          };
        });
      }

      let CSVData = convertArrayOfObjectsToCSV(tableDataClone);
      downloadCSV(`${this.shortCode} voter answers export.csv`, CSVData);
    },
    async exportPdfClicked() {
      try {
        this.exportReport({
          shortCode: this.shortCode,
          type: 'live-ballots'
        });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async resetAnswers() {
      // Reset selected voter answer(s)
      const answers = this.selected.map(answer => answer.answerKey);
      await resetMeetingVoterAnswers(this.shortCode, answers);
      this.getLatest();
      this.$events.$emit('toastEvent', 'Voter Answers have been reset');
      this.dialog.reset = false;
    },
    setQuestionSelected(question) {
      this.questionSelected = question;
    },
    setVoteSelected(vote) {
      this.voteSelected = vote;
    },
    filterByQuestion(questions) {
      if (this.questionSelected) {
        const questionFiltered = _.filter(
          questions,
          data => data.question === this.questionSelected
        );
        this.getListVotes(questionFiltered);
        return questionFiltered;
      }

      return questions;
    },
    filterByVote(questions) {
      if (this.voteSelected) {
        const questionsFilteredByVote = _.filter(questions, question =>
          _.includes(question.answers, this.voteSelected)
        );

        return questionsFilteredByVote;
      }

      return questions;
    },
    resetFilterByQuestion() {
      this.listVotes = []; // clean the list of vote according to the question selected
      this.voteSelected = null; // clean the vote selected
      this.questionSelected = null; // clean the question selected
      this.showFilterByVote = false; // hide the filter by vote
    },
    resetFilterByVote() {
      this.voteSelected = null; // clean the vote selected
    },
    updateRefreshStatus() {
      this.refreshStatus = moment(this.lastRefresh).fromNow();
    },
    getListVotes(questionFiltered) {
      this.showFilterByVote = true;
      let answers = [];

      questionFiltered.forEach(question => {
        answers = _.uniq([...answers, ...question.answers]);
      });

      this.listVotes = answers;
    },
    formatCustomData(vote) {
      let formattedData = [];

      for (const key in vote.customData) {
        // we already display shares in its own column
        if (key !== 'shares') {
          formattedData.push(`${key}: ${vote.customData[key]}`);
        }
      }

      formattedData = formattedData.join(', ');
      return formattedData;
    }
  }
};
</script>

<style scoped>
.voter-name {
  font-weight: 500;
  display: block;
}
.voter-email {
  font-size: 90%;
}
.refresh {
  text-align: right;
}
</style>
