<template>
  <section>
    <v-spacer />
    <v-layout row wrap class="mb-2">
      <v-alert :value="isSyncActive" type="info" outline class="w-full">
        <div class="alert-content">
          <div>
            <p>
              <b>{{ integrationName }} automatic daily sync is active</b>.
              Please make membership changes on your
              <b>{{ integrationName }} database</b>. Adding, updating and
              deleting members on GetQuorum is locked.
            </p>

            <p v-if="isAdmin">
              <i
                >** (Admins Only): You can still make changes, but they may be
                overwritten on the next sync. **</i
              >
            </p>
          </div>
          <v-tooltip bottom>
            <v-btn color="primary" slot="activator" @click="handleManualSync">
              <v-icon class="mr-1">sync</v-icon>
              Sync now
            </v-btn>
            <span
              >Get the latest data from {{ integrationName }}. This process can
              take a few minutes</span
            >
          </v-tooltip>
        </div>
      </v-alert>

      <div class="chips">
        <v-layout align-center justify-end row wrap>
          <v-chip
            v-for="filter in activeFilters"
            :key="filter.value"
            color="primary"
            small
            outline
            @click="updateFilters(filter.value)"
          >
            {{ filter.label }} <v-icon small right>close</v-icon>
          </v-chip>
        </v-layout>

        <div>
          <v-chip
            v-if="testVoters > 0"
            color="orange"
            text-color="black"
            label
            outline
          >
            <v-icon color="orange" left>info</v-icon>
            {{ testVoters }} test Voter(s)</v-chip
          >

          <v-chip
            v-if="multiUnitOwners"
            color="orange"
            text-color="black"
            label
            outline
            ><v-icon color="orange" left>info</v-icon>Multi-unit owners</v-chip
          >
        </div>
      </div>
    </v-layout>

    <v-alert
      v-show="numOfSelectedRows === tableData.length && tableData.length != 0"
      color="info"
      icon="info"
      outline
      style="text-align:center"
    >
      All {{ numOfSelectedRows }} units in this page are selected.
      {{
        notInPageUnitsNumber > 0
          ? `There are ${notInPageUnitsNumber} units not selected`
          : ''
      }}
    </v-alert>

    <v-spacer />
    <v-toolbar light class="grey lighten-4 elevation-1">
      <v-text-field
        prepend-icon="search"
        label="Search"
        single-line
        hide-details
        clearable
        :value="searchQuery"
        @input="updateSearchQuery($event)"
      />
      <v-spacer />
      <template class="buttons-slot">
        <div>
          <!-- Select Actions menu (hidden from non-admins) -->
          <v-menu v-if="isAdmin" offset-y>
            <v-btn slot="activator" light flat color="blue">
              Select Actions
              <v-icon>arrow_drop_down</v-icon>
            </v-btn>
            <v-list>
              <v-list-tile @click="selectRows('all')">
                <v-list-tile-content>
                  <v-list-tile-title>Select all units</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile @click="selectRows('clear')">
                <v-list-tile-content>
                  <v-list-tile-title>Deselect all units</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-menu>
          <!-- Bulk Actions menu:
          'Send Meeting Invite Email' visible for everyone
          'Update Addresses' hidden from non-admins
          'Remove Files' hidden from non-admins
          'Mark as Ineligible' visible for everyone
          'Delete Units' visible for admins/partner-serve collaborators, hidden from account users/moderators -->
          <v-menu offset-y>
            <v-btn slot="activator" light flat color="blue">
              Bulk Actions ({{ numOfSelectedRows }})
              <v-icon>arrow_drop_down</v-icon>
            </v-btn>
            <v-list>
              <v-list-tile
                v-if="isAdmin"
                @click="$emit('openDialog', 'generateControlNumbers')"
              >
                <v-list-tile-action>
                  <v-icon color="blue">add</v-icon>
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title>Add Control Numbers</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile
                :disabled="!isSelectedRows"
                @click="$emit('openDialog', 'bulkInvite')"
              >
                <v-list-tile-action>
                  <v-icon color="blue" :disabled="!isSelectedRows"
                    >mail_outline</v-icon
                  >
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title
                    >Send Meeting Invite Email</v-list-tile-title
                  >
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile
                :disabled="!isSelectedRows"
                @click="$emit('openDialog', 'bulkRegistrationPage')"
              >
                <v-list-tile-action>
                  <v-icon color="blue" :disabled="!isSelectedRows"
                    >file_copy</v-icon
                  >
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title
                    >Export Voter Registration Pages</v-list-tile-title
                  >
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile
                v-if="isAdmin"
                :disabled="!isSelectedRows"
                @click="$emit('openDialog', 'addresses')"
              >
                <v-list-tile-action>
                  <v-icon color="blue" :disabled="!isSelectedRows"
                    >edit_location</v-icon
                  >
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title>Update Addresses</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile
                v-if="isAdmin"
                :disabled="!isSelectedRows"
                @click="$emit('openDialog', 'bulkShares')"
              >
                <v-list-tile-action>
                  <v-icon color="blue" :disabled="!isSelectedRows"
                    >ballot</v-icon
                  >
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title>Update Shares</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile
                v-if="isAdmin"
                :disabled="!isSelectedRows"
                @click="$emit('openDialog', 'assignShares')"
              >
                <v-list-tile-action>
                  <v-icon color="blue" :disabled="!isSelectedRows"
                    >ballot</v-icon
                  >
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title>Assign Shares</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile
                v-if="isAdmin"
                :disabled="!isSelectedRows"
                @click="$emit('openDialog', 'assignSharesUndo')"
              >
                <v-list-tile-action>
                  <v-icon color="blue" :disabled="!isSelectedRows"
                    >ballot</v-icon
                  >
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title>Undo Assign Shares</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile
                v-if="isAdmin"
                :disabled="!isSelectedRows"
                @click="$emit('openDialog', 'bulkEmails')"
              >
                <v-list-tile-action>
                  <v-icon color="blue" :disabled="!isSelectedRows"
                    >contact_mail</v-icon
                  >
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title>Update Voter Email</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile
                v-if="isAdmin"
                :disabled="!isSelectedRows"
                @click="$emit('openDialog', 'deleteFiles')"
              >
                <v-list-tile-action>
                  <v-icon color="red" :disabled="!isSelectedRows"
                    >remove_circle_outline</v-icon
                  >
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title>Remove Files</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile
                v-if="isAdmin"
                :disabled="!isSelectedRows"
                @click="$emit('openDialog', 'removeCustomData')"
              >
                <v-list-tile-action>
                  <v-icon color="red" :disabled="!isSelectedRows"
                    >disabled_by_default</v-icon
                  >
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title>Remove Custom Data</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile
                :disabled="!isSelectedRows"
                @click="$emit('openDialog', 'bulkIneligible')"
              >
                <v-list-tile-action>
                  <v-icon color="red" :disabled="!isSelectedRows"
                    >do_not_disturb_alt</v-icon
                  >
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title>Mark as Ineligible</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile
                :disabled="!isSelectedRows"
                @click="$emit('openDialog', 'resetAttendance')"
              >
                <v-list-tile-action>
                  <v-icon color="red" :disabled="!isSelectedRows"
                    >restart_alt</v-icon
                  >
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title>Reset attendance</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile
                v-if="canDeleteUnits && !lockMembershipChanges"
                :disabled="!isSelectedRows"
                @click="$emit('openDialog', 'deleteUnits')"
              >
                <v-list-tile-action>
                  <v-icon color="red" :disabled="!isSelectedRows"
                    >delete_forever</v-icon
                  >
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title>Delete Units</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile
                v-if="canDeleteUnits && !lockMembershipChanges"
                @click="$emit('openDialog', 'deleteAllUnits')"
              >
                <v-list-tile-action>
                  <v-icon color="red" :disabled="!isSelectedRows"
                    >delete_forever</v-icon
                  >
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title>Delete All Units</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-menu>
        </div>

        <!-- Import Units button (hidden from non-admins) -->
        <import-units-button v-if="isAdmin" />

        <!-- Add New Unit button (hidden from account users, visible for everyone else) -->
        <v-btn
          v-if="canAddUnits && !lockMembershipChanges"
          class="primary"
          @click.native="$emit('openDialog', 'addUnit')"
        >
          <v-icon class="mr-1">add</v-icon>Add New Unit
        </v-btn>

        <!-- Open filter navigation drawer -->
        <v-btn color="primary" @click="drawer = !drawer"
          ><v-icon class="mr-1">filter_list</v-icon>Filters</v-btn
        >
      </template>
    </v-toolbar>

    <v-data-table
      :headers="isAdmin ? headers : userHeaders"
      :items="tableData"
      v-model="selected"
      @input="emitSelected"
      select-all
      :pagination.sync="pagination"
      :total-items="pagination.totalItems"
      :rows-per-page-items="pagination.rowsPerPageItems"
      :loading="loading"
      class="elevation-1"
    >
      <template slot="items" slot-scope="props">
        <!-- Units table rows (Address column hidden from non-admins) -->
        <tr
          :key="props.index"
          :class="{ 'expand-row-style': props.expanded === true }"
        >
          <td>
            <v-checkbox primary hide-details v-model="props.selected" />
          </td>
          <td
            class="text-xs-left text-highlight"
            @click="rowExpanded(props.item, props)"
          >
            {{ props.item.unit }}
          </td>
          <td
            v-if="isAdmin"
            class="text-xs-left text-highlight"
            @click="rowExpanded(props.item, props)"
          >
            <span>{{ props.item.address }}</span>
            <v-tooltip bottom>
              <v-icon
                small
                color="red"
                slot="activator"
                v-if="!props.item.addressValid"
                >error</v-icon
              >
              <span>No match for address</span>
            </v-tooltip>
          </td>
          <td
            class="text-xs-left name-overflow"
            @click="rowExpanded(props.item, props)"
          >
            {{ props.item.names }}
          </td>
          <td class="text-xs-left" @click="rowExpanded(props.item, props)">
            {{ props.item.emails }}
          </td>
          <!-- Status icons ('File Attached' hidden from non-admins; 'Ineligible', 'Consent', 'RSVP', & 'Attendance' visible for everyone) -->
          <td
            class="text-xs-left nowrap"
            @click="rowExpanded(props.item, props)"
          >
            <!-- Advance Vote Status Indicator -->
            <v-tooltip
              bottom
              v-if="props.item.proxy && !props.item.proxy.revokedOn"
            >
              <v-icon slot="activator" color="teal">how_to_vote</v-icon>
              <span>
                {{
                  props.item.proxy.source === 'voter'
                    ? 'Owner submitted advance vote'
                    : 'Advance vote submitted via dashboard'
                }}
              </span>
            </v-tooltip>

            <!-- Advance Ballot Status Indicator -->
            <v-tooltip bottom v-if="collectRtvBallots && props.item.ballots">
              <v-icon slot="activator" color="primary">how_to_vote</v-icon>
              <span>
                Owner submitted advance ballot
              </span>
            </v-tooltip>

            <v-tooltip
              bottom
              v-if="
                props.item.customData && props.item.customData.ineligible_reason
              "
            >
              <v-icon
                v-if="props.item.customData.ineligible_reason === 'arrears'"
                slot="activator"
                color="red lighten-1"
                v-show="props.item.customData.ineligible_reason"
                >money_off</v-icon
              >
              <v-icon
                v-if="props.item.customData.ineligible_reason === 'paper-proxy'"
                slot="activator"
                color="blue-grey lighten-2"
                v-show="props.item.customData.ineligible_reason"
                >assignment</v-icon
              >

              <span v-if="props.item.customData.ineligible_reason === 'arrears'"
                >Member is in arrears</span
              >
              <span
                v-if="props.item.customData.ineligible_reason === 'paper-proxy'"
                >Member has submitted paper proxy</span
              >
            </v-tooltip>

            <v-tooltip bottom v-if="isLiveStream(props.item)">
              <v-icon color="primary" slot="activator">live_tv</v-icon>
              <span>
                livestream
              </span>
            </v-tooltip>

            <v-tooltip bottom v-if="props.item.status.hasPanelists">
              <v-icon color="primary" slot="activator">voice_chat</v-icon>
              <span>
                Member is a panelist
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="props.item.controlNumber">
              <v-icon color="primary" slot="activator"
                >confirmation_number</v-icon
              >
              <span> Control Number: {{ props.item.controlNumber }} </span>
            </v-tooltip>

            <!-- Single Consent -->
            <v-tooltip
              bottom
              v-if="props.item.status.consent && displayConsentDetails"
            >
              <v-icon
                slot="activator"
                color="green lighten-1"
                v-show="props.item.status.consent"
                >email</v-icon
              >
              <span>
                Owner consented on:
                {{
                  props.item.status.consent
                    ? formatDateReadable(props.item.status.consent)
                    : 'Not Available'
                }}
              </span>
            </v-tooltip>

            <!-- Multi-consent: Notices -->
            <v-tooltip
              bottom
              v-if="props.item.status.multiConsent && displayConsentDetails"
            >
              <v-icon
                slot="activator"
                color="green lighten-1"
                v-show="props.item.status.multiConsent.noticeConsent"
                >email</v-icon
              >
              <span>
                Consented to Notices
              </span>
            </v-tooltip>

            <!-- Multi-consent: Voting -->
            <v-tooltip
              bottom
              v-if="props.item.status.multiConsent && displayConsentDetails"
            >
              <v-icon
                slot="activator"
                color="green lighten-1"
                v-show="props.item.status.multiConsent.votingConsent"
                >ballot</v-icon
              >
              <span>
                Consented to Voting
              </span>
            </v-tooltip>

            <v-tooltip bottom>
              <v-icon
                slot="activator"
                color="blue lighten-1"
                v-show="props.item.status.rsvp > 0"
                >how_to_reg</v-icon
              >
              <span>Member has RSVP'd</span>
            </v-tooltip>
            <v-tooltip bottom>
              <v-icon
                slot="activator"
                color="purple lighten-1"
                v-show="props.item.status.attendance > 0"
                >person_pin_circle</v-icon
              >
              <span>Member is present in meeting</span>
            </v-tooltip>
            <v-tooltip bottom>
              <v-icon
                slot="activator"
                color="blue-grey lighten-1"
                v-if="props.item.status.ineligibleReason === 'in-person'"
                >sim_card_download</v-icon
              >
              <span>Member is voting in-person (eg. paper ballot)</span>
            </v-tooltip>
            <v-tooltip bottom>
              <v-icon
                v-show="props.item.status.lastInviteDate"
                slot="activator"
                color="primary"
                >insert_invitation</v-icon
              >
              <span
                >Last meeting invite was sent on:
                {{
                  props.item.status.lastInviteDate
                    | formatDateHumanReadableAlt(true, meetingTimezone)
                }}</span
              >
            </v-tooltip>
            <v-tooltip bottom v-if="isAdmin">
              <v-icon
                slot="activator"
                color="primary lighten-1"
                v-show="props.item.status.attachment"
                >attachment</v-icon
              >
              <span>File attached</span>
            </v-tooltip>

            <v-tooltip
              bottom
              v-if="
                props.item.customData &&
                  (props.item.customData.vantaca_id ||
                    props.item.customData.leland_caliber_id)
              "
            >
              <v-icon
                :color="props.item.lastSync ? 'orange' : 'primary'"
                slot="activator"
                >sync</v-icon
              >
              <span>
                Integration ID:
                {{
                  props.item.customData.vantaca_id ||
                    props.item.customData.leland_caliber_id
                }}
              </span>
              <br />
              <span v-if="props.item.lastSync">
                last Sync:
                {{
                  props.item.lastSync.timestamp
                    | formatDateHumanReadableAlt(true, meetingTimezone)
                }}
              </span>
            </v-tooltip>

            <v-tooltip
              bottom
              v-if="
                props.item.customData &&
                  props.item.customData.leland_invalid_email_value
              "
            >
              <v-icon color="red" slot="activator">warning</v-icon>
              <span>
                This unit was previously synced with an invalid email value from
                the Leland-Caliber API. The GQ system cannot load this invalid
                email and would have set it to the placeholder
                noemail@getquorum.com instead.
              </span>
            </v-tooltip>
          </td>
          <td class="text-xs-left" @click="rowExpanded(props.item, props)">
            {{ props.item.customFields }}
          </td>
        </tr>
      </template>
      <!-- Expanded Row view -->
      <template slot="expand" slot-scope="props">
        <v-card flat>
          <v-card-text>
            <!-- Unit/Address (hidden from moderators/account users, visible for partner-serve/admins) -->
            <v-layout v-if="canEditUnits">
              <p>
                <b>Unit / Address</b>:
                <v-chip
                  small
                  color="grey lighten-4"
                  @click="$emit('openDialog', 'editUnit')"
                  >{{ props.item.unit }} - {{ props.item.address }}</v-chip
                >
              </p>
              <v-spacer />
            </v-layout>
            <!-- Control Number (hidden from moderators, visible for account users/partner-serve/admins) -->
            <p v-if="canEditUnits || canEditUnitAddresses">
              <b>Control Number</b>:
              <v-chip
                small
                color="grey lighten-4"
                @click="$emit('openDialog', 'editUnit')"
              >
                {{ props.item.controlNumber }}
              </v-chip>
            </p>
            <!-- Mailing Address (hidden from moderators, visible for account users/partner-serve/admins) -->
            <p v-if="canEditUnits || canEditUnitAddresses">
              <b>Mailing Address</b>:
              <v-chip
                small
                color="grey lighten-4"
                @click="$emit('openDialog', 'editUnit')"
              >
                {{ props.item.mailAddress1 }} {{ props.item.mailAddress2 }}
                {{ props.item.mailCity }} {{ props.item.mailProv }}
                {{ props.item.mailPostal }} {{ props.item.mailCountry }}
              </v-chip>
            </p>

            <!-- Custom Fields:
            Clicking/editing custom fields not allowed for account users, but allowed for moderators/partner-serve/admins;
            'Add Field' chip hidden from account users/moderators, visible for partner-serve/admins;
            'Mark as Ineligible' visible for everyone -->
            <v-layout class="mb-3" row wrap justify-start v-if="isCustomFields">
              <div class="pt-1"><b>Custom Field</b>:</div>
              <div
                v-for="(value, key, index) in filterExpandedRowCustomData(
                  props.item.customData
                )"
                :key="index"
              >
                <v-chip
                  medium
                  color="grey lighten-4"
                  @click="
                    canEditUnitCustomFields && !lockMembershipChanges
                      ? editCustomFieldClicked(props.item, key, value)
                      : null
                  "
                  ><v-icon
                    class="close-button"
                    v-if="key === 'ineligible_reason'"
                    left
                    @click.stop="handleTagClick(props.item, key)"
                    >highlight_off</v-icon
                  >
                  {{ key }}:
                  <b>{{ value }}</b>
                </v-chip>
              </div>
              <v-chip
                v-if="canEditUnits && !lockMembershipChanges"
                medium
                outline
                color="primary lighten-1"
                @click="addCustomFieldClicked(props.item)"
              >
                <v-icon>add</v-icon>Add Field
              </v-chip>

              <span v-if="showUnitTags">
                <v-chip
                  v-if="
                    props.item.customData &&
                      !props.item.customData.ineligible_reason
                  "
                  medium
                  outline
                  color="primary lighten-1"
                  @click="handleTagClick(props.item, 'shares')"
                >
                  <v-icon class="mr-2">do_not_disturb_alt</v-icon>Mark as
                  Ineligible
                </v-chip>
              </span>
            </v-layout>

            <!-- File Attached (hidden from non-admins) -->
            <v-layout v-if="isAdmin && props.item.files.length > 0">
              <b>File Attached:</b>
              <v-layout row v-for="file in props.item.files" :key="file.key">
                <v-chip
                  @input="emitDeleteFile(file)"
                  color="grey lighten-4"
                  small
                  close
                  @click="emitDownloadFile(file.package)"
                >
                  <v-icon left>attachment</v-icon>
                  {{ file.package.name }}
                </v-chip>
              </v-layout>
            </v-layout>

            <!-- Owners (visible for everyone) -->
            <div>
              <b>Owners:</b>
            </div>
            <v-container fluid grid-list-md>
              <v-layout row wrap>
                <v-flex xs6 v-for="voter in props.item.voters" :key="voter.id">
                  <owner-card
                    :voter="voter"
                    :meeting-timezone="meetingTimezone"
                    :display-consent-details="displayConsentDetails"
                    :consent-format="consentFormat"
                    @owner-clicked="editOwnerClicked"
                    @send-invite="sendInvite"
                  />
                </v-flex>
                <v-flex xs6 align-self-center v-if="!lockMembershipChanges">
                  <v-chip
                    outline
                    color="primary lighten-1"
                    @click.native="$emit('openDialog', 'addVoter')"
                  >
                    <v-icon>add</v-icon>Add Owner
                  </v-chip>
                </v-flex>
              </v-layout>
            </v-container>

            <!-- Proxy (visible for everyone) -->
            <div class="font-weight-bold">Proxy:</div>
            <v-layout row wrap justify-start align-center>
              <div v-if="props.item.proxy && !props.item.proxy.revokedOn">
                <v-chip
                  class="pa-4"
                  color="grey lighten-4"
                  v-if="props.item.proxy && !props.item.proxy.revokedOn"
                >
                  <div class="pr-2">
                    <div>
                      <b>Submitted By:</b>
                      <span v-if="props.item.proxy.source === 'voter'">
                        {{ props.item.proxy.name }}
                      </span>
                      <span v-else>
                        {{ props.item.proxy.metaData.source.name }}
                      </span>
                    </div>
                    <div>
                      <b>Submission Date:</b>
                      {{ formatDateReadable(props.item.proxy.createdAt) }}
                    </div>
                    <div>
                      <b>Proxy Holder:</b>
                      {{ props.item.proxy.holderName }}
                    </div>
                    <div>
                      <b>Type:</b>
                      {{
                        props.item.proxy.source === 'voter'
                          ? 'Electronic'
                          : 'Paper'
                      }}
                    </div>
                  </div>
                  <div>
                    <v-tooltip bottom>
                      <v-btn
                        fab
                        outline
                        small
                        :disabled="
                          !props.item.proxy.fileUrl ||
                            disableViewFile({ proxy: props.item.proxy })
                        "
                        slot="activator"
                        color="primary"
                        @click.stop="$emit('viewProxy', props.item.proxy)"
                        ><v-icon>insert_drive_file</v-icon></v-btn
                      >
                      <span v-if="props.item.proxy.fileUrl"
                        >View Proxy PDF</span
                      >
                      <span v-else
                        >No record was provided for this submission</span
                      >
                    </v-tooltip>

                    <v-tooltip bottom>
                      <v-btn
                        fab
                        outline
                        small
                        slot="activator"
                        color="error"
                        @click.stop="$emit('openDialog', 'revokeProxy')"
                        :disabled="revocationProxyDisabled"
                        ><v-icon>delete_forever</v-icon></v-btn
                      >
                      <span v-if="!revocationProxyDisabled">Revoke Proxy</span>
                      <span v-else>
                        Revoke Proxy: The proxy revocation period has closed
                      </span>
                    </v-tooltip>
                  </div>
                </v-chip>
              </div>
              <div v-else>
                <!-- Check if user has create proxy permission -->
                <div v-if="canAddProxy">
                  <!-- Enabled button state -->
                  <v-chip
                    v-if="props.item.voters.length > 0"
                    outline
                    color="primary"
                    @click="$emit('openDialog', 'addProxy')"
                  >
                    <v-icon>add</v-icon>Add Proxy
                  </v-chip>

                  <!-- Disabled button state -->
                  <div v-else>
                    <v-tooltip right>
                      <v-chip slot="activator" outline color="grey lighten-2">
                        <v-icon>add</v-icon>Add Proxy
                      </v-chip>
                      <span>Owners must be added first</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </v-layout>
            <!-- Ballot (if we're collecting advance ballots - visible for everyone) -->
            <div v-if="collectRtvBallots" class="font-weight-bold mt-3">
              Ballot:
            </div>
            <v-layout
              v-if="collectRtvBallots"
              row
              wrap
              justify-start
              align-center
            >
              <div v-if="props.item.ballots">
                <template v-for="ballot in props.item.ballots">
                  <v-chip
                    class="pa-4"
                    color="grey lighten-4"
                    v-if="showBallot(ballot)"
                    :key="ballot.ballotKey"
                  >
                    <div class="pr-2">
                      <div>
                        <b>Question:</b>
                        <span> "{{ ballot.question }}" </span>
                      </div>
                      <div>
                        <b>Submitted By:</b>
                        <span>
                          {{ ballot.voterName }}
                        </span>
                      </div>
                      <div>
                        <b>Submission Date:</b>
                        {{ formatDateReadable(ballot.createdAt) }}
                      </div>
                    </div>
                  </v-chip>
                </template>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </template>
    </v-data-table>
    <v-navigation-drawer v-model="drawer" fixed temporary right width="300">
      <unit-filter-drawer
        :current-meeting="currentMeeting"
        @update-filters="setFiltersAndUpdateUnits"
      />
    </v-navigation-drawer>

    <!-- Sync Dialog -->
    <sync-dialog
      :is-open="syncingDialog"
      :is-syncing="isSyncing"
      @close-dialog="handleCloseSyncDialog"
    />

    <confirm-sync-dialog
      :is-open="openConfirmSyncDialog"
      :ttl="syncTtl"
      :is-admin="isAdmin"
      :loading="isSyncing"
      :integration-name="integrationName"
      :meeting-timezone="meetingTimezone"
      @force="handleForceSync"
      @confirm="handleMembershipSync"
      @close="openConfirmSyncDialog = false"
    />
  </section>
</template>

<script>
import _ from 'lodash';
import Bowser from 'bowser';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { formatDateHumanReadable } from '@/helpers';
import { config } from '@/config/index.js';
import ImportUnitsButton from '@/components/ImportUnitsButton';
import UnitFilterDrawer from '@/components/UnitFilterDrawer';
import OwnerCard from '@/components/OwnerCard';
import SyncDialog from '@/components/dialogs/SyncDialog.vue';
import ConfirmSyncDialog from '@/components/dialogs/ConfirmSyncDialog.vue';
import checkScope from '@/lib/check-user-scopes';
import { formatDateHumanReadableAlt } from '@/filters';

export default {
  name: 'MeetingUnitsListViewTable',
  components: {
    ImportUnitsButton,
    OwnerCard,
    UnitFilterDrawer,
    SyncDialog,
    ConfirmSyncDialog
  },
  props: {
    unitsListData: {
      type: Array,
      default() {
        return [];
      }
    },
    displayConsentDetails: {
      type: Boolean,
      default: true
    },
    currentMeeting: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  filters: {
    formatDateHumanReadableAlt
  },
  data() {
    return {
      loading: false,
      selected: [],
      filter_toggles: [],
      drawer: false,
      downloading: false,
      syncTtl: 0,
      openConfirmSyncDialog: false,
      headers: [
        { text: 'Unit', value: 'unit', align: 'left' },
        { text: 'Address', value: 'address', align: 'left', sortable: false },
        {
          text: 'Owner Name(s)',
          value: 'names',
          align: 'left',
          sortable: false
        },
        { text: 'Email(s)', value: 'emails', align: 'left', sortable: false },
        { text: 'Status', value: 'status', align: 'left', sortable: false },
        {
          text: 'Custom Data',
          value: 'customData',
          align: 'left',
          sortable: false
        }
      ],
      VUE_APP_TOGGLE_DOCX_CONVERSION:
        config.featureToggle.VUE_APP_TOGGLE_DOCX_CONVERSION
    };
  },
  watch: {
    selected: function(selected) {
      this.emitSelected(selected);
    },
    pagination: {
      async handler() {
        this.loading = true;
        await this.getMeetingUnits({
          shortCode: this.currentMeeting.shortCode,
          customFieldName: this.customFieldName,
          customFieldValue: this.customFieldValue
        });
        this.loading = false;
      }
    },
    tableData(data) {
      // If table data is updated and there are rows selected, update the selected rows
      if (data && this.selected.length > 0) {
        const updatedSelected = this.selected.map(selectedObj => {
          const matchingObj = data.find(
            tableObj => tableObj.id === selectedObj.id
          );
          return matchingObj ? { ...matchingObj } : selectedObj;
        });

        // Update the selected value
        this.selected = updatedSelected;
      }
    }
  },
  mounted() {
    this.setDefaultPaginationValues(this.$route.query.search);
  },
  computed: {
    ...mapGetters(['isAdmin', 'scopes', 'getScopeByAction']),
    ...mapGetters('meetings/units', [
      'searchQuery',
      'filters',
      'pagination',
      'customDataKeys',
      'activeFilters',
      'syncingDialog',
      'isSyncing'
    ]),
    ...mapGetters('accounts', ['accountByKey']),
    /**
     * A computed property that formats the units list data for display in membership datatable
     *
     * @returns {Array} items - The formatted data array containing the following properties for each unit:
     *  - names {string} - Comma-separated list of voter names
     *  - emails {string} - Comma-separated list of voter emails
     *  - addressValid {boolean} - Whether the address is valid
     *  - customFields {string|null} - Comma-separated list of custom fields
     *  - status {Object}
     *      - consent {Date} - Consent date
     *      - rsvp {number} - Number of RSVPs
     *      - lastInviteDate {Date|null} - The last invite date or null
     *      - attendance {number} - Number of attendees from this unit
     *      - attachment {boolean} - Whether this record has a file attachment (ie. cover letters)
     *      - hasPanelists {boolean} - Whether there are panelists
     *      - ineligibleReason {string|null} - Reason for ineligibility for voting
     */
    tableData() {
      return this.unitsListData.map(row => {
        // Format custom property values
        const names = row.voters.map(voter => voter.name).join(', ');
        const emails = row.voters.map(voter => voter.email).join(', ');
        const addressValid = this.currentMeeting.addresses.includes(
          row.address
        );
        const rsvp = row.voters.filter(voter => voter.attending).length;
        const attendance = row.voters.filter(voter => voter.inAttendance)
          .length;

        // Multi-consent specific properties
        // If any voter has a notice or voting consent, return true for that consent type
        const multiConsent =
          this.consentFormat === 'multi-consent'
            ? {
                noticeConsent: row.voters.some(
                  voter => voter.consent.notice_consent
                ),
                votingConsent: row.voters.some(
                  voter => voter.consent.voting_consent
                )
              }
            : null;

        // Format customFields
        const customFields = this.formatCustomFields(row.customData);

        // Check if voter has been sent an invite
        const votersWithInvite = row.voters.filter(
          voter => voter.lastInviteDate !== null
        );

        // Set each voters browser data if available
        row.voters.forEach(voter => {
          if (voter.browserData) {
            voter.parsedBrowserData = this.setVotersBrowserData(voter);
          }
          voter.unit = row.unit;
          voter.address = row.address;
        });

        // Check if any voters are panelists
        const hasPanelists = row.voters.some(voter => voter.isPanelist);

        // Check if unit is ineligible in-person
        const ineligibleReason =
          row.customData?.ineligible_reason || row.customData?.ineligibleReason;

        // Check if unit has been synced
        const voterWithSync = row.voters.find(
          voter => voter.metaData?.integration?.lastSync
        );

        if (voterWithSync) {
          row.lastSync = voterWithSync.metaData.integration.lastSync;
        }

        // Add new properties for each row
        return {
          ...row,
          names,
          emails,
          addressValid,
          customFields,
          status: {
            consent: row.consentOn,
            multiConsent,
            rsvp,
            lastInviteDate:
              votersWithInvite.length > 0
                ? _.last(votersWithInvite).lastInviteDate
                : null,
            attendance,
            attachment: row.files.length > 0,
            hasPanelists,
            ineligibleReason
          }
        };
      });
    },
    pagination: {
      get: function() {
        return this.$store.getters['meetings/units/pagination'];
      },
      set: function(value) {
        this.setPagination(value);
      }
    },
    multiUnitOwners: {
      get: function() {
        return this.$store.getters['meetings/units/meetingUnitMultiOwners'];
      }
    },
    testVoters: {
      get: function() {
        return this.$store.getters['meetings/units/meetingUnitTestVoters'];
      }
    },
    meetingTimezone() {
      return this.currentMeeting.meetingTimezone
        ? this.currentMeeting.meetingTimezone
        : 'America/Toronto';
    },
    availableCustomFields() {
      return _.union(
        this.customDataKeys,
        this.currentMeeting.options.customUnitFields
      );
    },
    enableCustomFieldInput() {
      return (
        this.customFieldName !== undefined && this.customFieldName.length > 0
      );
    },
    userHeaders() {
      // non-admins don't see the 'Address' column
      return _.remove(this.headers, function(header) {
        return header.value !== 'address';
      });
    },
    isSelectedRows() {
      return this.selected.length > 0;
    },
    isCustomFields() {
      return (
        _.get(this.currentMeeting, 'options.customUnitFields', []).length > 0
      );
    },
    numOfSelectedRows() {
      return this.selected.length;
    },
    showUnitTags() {
      // we don't need to show Unit tags if campaign
      // is for a Budget/PIC/ICU
      return (
        this.currentMeeting.options.type !== 'budget' &&
        this.currentMeeting.options.type !== 'pic' &&
        this.currentMeeting.options.type !== 'icu'
      );
    },
    notInPageUnitsNumber() {
      return this.pagination.totalItems - this.numOfSelectedRows;
    },
    canDeleteUnits() {
      return (
        this.isAdmin ||
        checkScope(
          this.scopes,
          this.currentMeeting.shortCode,
          'meeting.units.delete'
        )
      );
    },
    canAddUnits() {
      return (
        this.isAdmin ||
        checkScope(
          this.scopes,
          this.currentMeeting.shortCode,
          'meeting.units.create'
        )
      );
    },
    canEditUnits() {
      return (
        this.isAdmin ||
        checkScope(
          this.scopes,
          this.currentMeeting.shortCode,
          'meeting.units.update'
        )
      );
    },
    canEditUnitAddresses() {
      return (
        this.isAdmin ||
        checkScope(
          this.scopes,
          this.currentMeeting.shortCode,
          'meeting.units.address.update'
        )
      );
    },
    canEditUnitCustomFields() {
      return (
        this.isAdmin ||
        checkScope(
          this.scopes,
          this.currentMeeting.shortCode,
          'meeting.units.update'
        ) ||
        checkScope(
          this.scopes,
          this.currentMeeting.shortCode,
          'meeting.units.eligibility.update'
        )
      );
    },
    canAddProxy() {
      if (this.isAdmin) {
        return true;
      }

      return this.getScopeByAction(
        this.currentMeeting.shortCode,
        'meeting.proxies.create'
      );
    },
    collectRtvBallots() {
      return this.currentMeeting.options?.collectRtvBallots;
    },
    revocationProxyDisabled() {
      if (this.isAdmin || this.canRevokeProxies) {
        return false;
      }

      if (this.currentMeeting.portalRevocationsClosedAt) {
        return true;
      }

      const revocationDeadlineDate = this.currentMeeting.revocationDeadlineDate;

      if (!revocationDeadlineDate) return false;

      const now = moment().tz(this.currentMeeting.meetingTimezone);

      if (now.isAfter(moment(revocationDeadlineDate))) {
        return true;
      }
      return false;
    },
    canRevokeProxies() {
      return checkScope(
        this.scopes,
        this.currentMeeting.shortCode,
        'meeting.proxies.delete.full'
      );
    },
    consentFormat() {
      return this.currentMeeting?.options?.consentFormat;
    },
    isSyncActive() {
      const isSyncEnabled = _.get(
        this.currentMeeting,
        'options.integration.enableVoterSync',
        false
      );

      const syncEndDate = _.get(
        this.currentMeeting,
        'options.integration.syncEndDate'
      );

      return isSyncEnabled && moment().isBefore(syncEndDate);
    },
    lockMembershipChanges() {
      if (this.isAdmin) {
        return false;
      }

      return this.isSyncActive;
    },
    integrationName() {
      // check if meeting has vantaca integration
      const vantacaIntegration = _.some(this.unitsListData, u => {
        return 'vantaca_id' in u.customData;
      });

      if (vantacaIntegration) {
        return 'Vantaca';
      }

      const caliberIntegration = _.some(this.unitsListData, u => {
        return 'leland_caliber_id' in u.customData;
      });

      if (caliberIntegration) {
        return 'Caliber';
      }

      return '';
    },
    currentAccount() {
      const accountKey = this.currentMeeting.accountKey;
      return this.accountByKey(accountKey);
    }
  },
  methods: {
    ...mapActions('meetings/units', [
      'setFilters',
      'getMeetingUnits',
      'setPagination',
      'setCustomFilters',
      'setSyncDialog',
      'setIsSyncing'
    ]),
    ...mapActions('accounts', [
      'getAssociationMemberships',
      'syncMembershipsData'
    ]),
    ...mapActions('meetings', ['getMeetingSyncTtl']),
    // Row Selection (radio boxes)
    emitSelected(selected) {
      this.$emit('selected', selected);
    },
    // Expanded row selection
    rowExpanded(item, props) {
      // Expands row and sets selected unit
      props.expanded = !props.expanded;
      this.$emit('rowExpand', item);
    },
    // Owner Dialog
    editOwnerClicked(voter) {
      if (!this.isAdmin && this.isSyncActive) {
        // do nothing, voter cannot be edited manually
        return;
      }
      this.$emit('openDialog', 'editVoter');
      this.$emit('voterSelected', voter);
    },
    // Custom field dialogs
    editCustomFieldClicked(item, key, value) {
      let payload = {
        item: item,
        key: key,
        value: value
      };
      this.$emit('openDialog', 'editCustomField');
      this.$emit('customFieldSelected', payload);
    },
    addCustomFieldClicked() {
      this.$emit('openDialog', 'addCustomField');
    },
    handleTagClick(unit, tag) {
      // decide if we're adding or removing the tag
      if (!unit.customData || !unit.customData.ineligible_reason) {
        this.$emit('openDialog', 'markIneligible');
      } else {
        this.$emit('openDialog', 'markEligible');
      }
    },
    emitDeleteFile(file) {
      this.$emit('deleteFile', file);
    },
    emitDownloadFile(file) {
      this.$emit('downloadFile', file);
    },
    setDefaultPaginationValues(search) {
      this.setPagination({
        searchQuery: search,
        descending: false,
        sortBy: 'unit',
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        rowsPerPageItems: [25, 50, 100, 200]
      });
    },
    sendInvite(voter) {
      this.$emit('sendInvite', voter);
    },
    setVotersBrowserData(voter) {
      const browser = Bowser.getParser(voter.browserData);

      return {
        device: browser.getPlatformType(),
        browser: browser.getBrowserName(),
        version: `v${browser.getBrowserVersion()}`,
        os: `${browser.getOSName()} ${browser.getOSVersion()}`
      };
    },
    formatCustomFields(customData) {
      if (!customData) return null;
      const filterKeyArray = ['assigned_to_unit_id'];

      // Formats all except the "assigned_to_unit_id", only needed for bulk undo assign shares functionality
      return Object.entries(customData)
        .filter(([key]) => !filterKeyArray.includes(key))
        .map(([key, value]) => `${key}: ${value}`)
        .join(', ');
    },
    // Filters out customData object for visibility in expanded row. Filtered fields cannot be edited.
    filterExpandedRowCustomData(customData) {
      const filterKeyArray = ['assigned_to_unit_id'];

      return Object.entries(customData)
        .filter(([key]) => !filterKeyArray.includes(key))
        .reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {});
    },

    // Helpers
    formatDateReadable(date) {
      return formatDateHumanReadable(date, false, this.meetingTimezone);
    },
    isInvalidEmail(email) {
      return email === 'noemail@getquorum.com';
    },
    updateSearchQuery: _.debounce(function(value) {
      this.setDefaultPaginationValues(value === null ? '' : value);
    }, 350),
    selectRows(type) {
      this.selected = this.getFilteredSelection(type);
    },
    // Returns selected rows based on filter type
    getFilteredSelection(type) {
      switch (type) {
        case 'all':
          return this.unitsListData;
        case 'clear':
          return [];
        default:
          break;
      }
    },
    updateFilters(value) {
      const activeFilters = this.filters.filter(item => item !== value);
      this.setFiltersAndUpdateUnits(activeFilters);

      if (value === 'customData') {
        this.setCustomFilters([]);
      }
    },
    async setFiltersAndUpdateUnits(filters = []) {
      this.setFilters(filters);
      this.selectRows('clear');
      this.loading = true;
      await this.getMeetingUnits({
        shortCode: this.currentMeeting.shortCode
      });
      this.loading = false;
    },
    disableViewFile({ proxy }) {
      // if user is admin, allow view/download PDF
      if (this.isAdmin) {
        return false;
      }

      // if proxy source user, allow view/download PDF
      if (proxy.source === 'user') {
        return false;
      }

      // if the user has the permission to view the PDF (collaborators scope), allow view/download PDF
      const hasCollaboratorPermission = this.getScopeByAction(
        this.currentMeeting.shortCode,
        'meeting.proxies.read'
      );

      const now = moment().tz(this.currentMeeting.meetingTimezone);
      const enableProxyDetails = this.currentMeeting?.options
        ?.enableProxyDetails;

      if (!hasCollaboratorPermission) {
        // For account users: never | always | after | null
        if (enableProxyDetails === 'never') {
          return true;
        } else if (enableProxyDetails === 'always') {
          return false;
        } else if (
          (enableProxyDetails === 'after' || !enableProxyDetails) &&
          moment(now).isAfter(this.currentMeeting.expiryDate)
        ) {
          return false;
        }
      } else if (hasCollaboratorPermission) {
        // For collaborators
        return false;
      }

      return true;
    },
    isLiveStream(item) {
      return item.customData?.livestream?.toLowerCase() === 'true';
    },
    showBallot(ballot) {
      if (this.currentMeeting.options.votingOption === 'Proxy-Ballot') {
        if (ballot.isBallot) return true;
        else return false;
      }
      return true;
    },
    async syncMemberships({ force = false } = {}) {
      this.setIsSyncing({ isSyncing: true });

      const accountKey = this.currentMeeting.accountKey;
      const shortCode = this.currentMeeting.shortCode;

      const accountIntegrationKey = _.get(
        this.currentAccount,
        'integrations[0].key',
        null
      );

      try {
        await this.syncMembershipsData({
          shortCode,
          accountKey,
          accountIntegrationKey,
          force
        });

        this.setSyncDialog({ isOpen: true });
      } catch (err) {
        this.setIsSyncing({ isSyncing: false });
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async handleManualSync() {
      // get the ttl for the current meeting to know if sync window is available or not
      await this.getSyncTtl();

      // open the confirmation dialog
      this.openConfirmSyncDialog = true;
    },
    async getSyncTtl() {
      try {
        const res = await this.getMeetingSyncTtl({
          shortCode: this.currentMeeting.shortCode
        });

        this.syncTtl = res.ttl;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async handleMembershipSync(value) {
      if (!value) {
        this.openConfirmSyncDialog = false;
        return;
      }

      await this.syncMemberships();
      this.openConfirmSyncDialog = false;
    },

    handleCloseSyncDialog() {
      this.setSyncDialog({ isOpen: false });
    },

    async handleForceSync() {
      await this.syncMemberships({ force: true });
      this.openConfirmSyncDialog = false;
    }
  }
};
</script>

<style scoped>
.text-overflow {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.name-overflow {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.expand-row-style {
  background-color: #eeeeee;
}
.close-button:hover {
  color: #757575;
}
.chips {
  width: 50%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.alert-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.w-full {
  width: 100%;
}

.alert-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 15px;
}
</style>
